import type { FC } from 'react';
import React, { forwardRef } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N200 } from '@atlaskit/theme/colors';
import type { PopupComponentProps, TriggerProps } from '@atlaskit/popup';
import Popup from '@atlaskit/popup';
import Button from '@atlaskit/button/standard-button';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';

import { useHoverPoweredByPopup } from './useHoverPoweredByPopup';
import { PoweredByTemplatesPopup } from './PoweredByTemplatesPopup';

export const i18n = defineMessages({
	poweredByTemplates: {
		id: 'powered-by-templates.byline.text',
		defaultMessage: 'with a template',
		description: 'Label for template used to create a page',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PoweredByBylineWrapper = styled.span({
	display: 'inline-block',
	color: token('color.text.subtlest', N200),
	'&:hover': {
		textDecoration: 'underline',
		cursor: 'pointer',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'&:before': {
			/* for IE <= 11 */
			textDecoration: 'none',
		},
	},
	'&:focus': {
		textDecoration: 'underline',
	},
});

export type PoweredByComponentProps = {
	templateId: string;
};

export const PoweredByTemplates: FC<PoweredByComponentProps> = ({ templateId }) => {
	const { isPopupOpen, startOpenPopupTimer, startClosePopupTimer } = useHoverPoweredByPopup();

	const containerListeners = {
		onMouseLeave: startClosePopupTimer,
		onMouseEnter: startOpenPopupTimer,
		onKeyDown: startOpenPopupTimer,
		onBlur: startClosePopupTimer,
	};

	/** Uses a custom popup container because the template card has its own wrapper container. */
	const CustomPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>((props, ref) => {
		const { style, children, ...extraProps } = props;
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			<div {...extraProps} ref={ref} style={style}>
				{children}
			</div>
		);
	});

	const PopupContent = () => (
		<div {...containerListeners}>
			<PoweredByTemplatesPopup templateId={templateId} />
		</div>
	);

	const TriggerComponent = (triggerProps: TriggerProps) => (
		<PoweredByBylineWrapper
			{...triggerProps}
			{...containerListeners}
			data-testid="powered-by-byline"
		>
			<Button
				appearance="subtle-link"
				spacing="none"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
				style={{ fontWeight: 'unset' }}
			>
				<FormattedMessage {...i18n.poweredByTemplates} />
			</Button>
		</PoweredByBylineWrapper>
	);

	return (
		<ErrorBoundary attribution={Attribution.TAILORED_EXPERIENCES}>
			<Popup
				isOpen={isPopupOpen}
				onClose={startClosePopupTimer}
				content={PopupContent}
				trigger={TriggerComponent}
				placement="bottom-start"
				popupComponent={CustomPopupContainer}
				autoFocus={false}
				offset={[-8, 0]}
			/>
		</ErrorBoundary>
	);
};
